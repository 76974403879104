
import React from 'react';
import me from '../content/me.png';
import './AboutSection.css';

// animation package
import { Fade } from "react-awesome-reveal";

export const AboutSection = () => {

    const writeup = {
        first: "My passion lies in blending technology with financial markets—particularly in algorithmic trading and quantitative finance. Drawing on my academic background and hands-on project experience, I'm constantly seeking new opportunities to broaden my expertise in these areas.",
        second: "Some technologies I've been working with:",
        stack: [
            "Python",
            "C++",
            "Java",
            "R",
            "Julia",
            "GO",],
        third: "Outside of work, I enjoy tackling personal projects (like the ones featured below) and connecting with others who share my interests in technology, data science, and finance.",
    }

    return (
        <section className='aboutBackground'>
            <div className='aboutSectionText'>
                <div className='aboutSectionTitle'>
                    <h1 className='sectionNumber'> 01.</h1>
                    <h2 className='sectionTitle'> About me</h2>
                </div>

                <div className='aboutSectionMainText'>
                    <Fade direction='up' triggerOnce={false}>
                        <div>
                            <p>{writeup["first"]}</p>
                            <p>{writeup["second"]}</p>
                        </div>
                    </Fade>
                    <div className='aboutSectionSkills'>
                        <ul className="tech-stack">
                            {writeup["stack"].map(function (tech, i) {
                                return (
                                    <Fade direction='up' cascade delay={500+i*250} triggerOnce={false}>
                                        <li key={i}>{tech}</li>
                                    </Fade>
                                );
                            })}
                        </ul>
                    </div>
                    <Fade direction='up' triggerOnce={false}>
                        <p>{writeup["third"]}</p>
                    </Fade>
                </div>

            </div>
            <div className='aboutSectionImage'>
                <img src={me} alt='myself' />
            </div>
        </section>
    )
}