
import React from "react";
import './Footer.css'

export const Footer = () => {

    return (
        <section className="footer">
            <div className="footerContent">
                <div>Built and designed by Oleg Glotov and Alexander Mukhin. </div>
                <div> - </div>
                <div>All rights reserved. © (whatever this means)</div>
            </div>
        </section>
    )
}